<style scoped lang="less">
  .mobile-wap-menu {
    background-color: #FFF;
  }
  .header {
    position: relative;
    background-image: url('/static/images/mobile/menu/head-bg-index.png');
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    .bg2 {
      margin: 0 auto;
      display: block;
      width: calc(100% - 40px);
      position: relative;
      z-index: 1;
    }
  }
  .menus {
    display: flex;
    flex-wrap: wrap;
    .item {
      padding: 10px;
      box-sizing: border-box;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 46px;
        height: 40px;
      }
      span {
        margin-top: 10px;
        text-align: center;
        color: #000;
      }
    }
  }
  .grid {
    padding: 12px;
    box-sizing: border-box;
    .item {
      float: left;
      width: 50%;
      padding: 12px;
      box-sizing: border-box;
      position: relative;
      height: 231px;
      .wrap {
        box-sizing: border-box;
        height: 100%;
        padding: 10px;
        background-color: #F9798F;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 37px;
            height: 37px;
          }
        }
        .name {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #FFF;
        }
        .content {
          flex: 1;
          height: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="mobile-wap-menu">
    <div class="header">
      <img class="bg2" src="/static/images/mobile/menu/7.png" />
    </div>
    <div class="grid">
      <router-link class="item" :to="{name: 'wap.all_info'}" v-if="workerId">
        <div class="wrap" style="background-image:url('/static/images/mobile/menu/8.png');background-repeat: no-repeat;background-position: right bottom;background-size: 45%;">
          <div class="title">
            <div class="name">个人中心</div>
            <img src="/static/images/mobile/menu/10.png" />
          </div>
          <person class="content" :worker-id="workerId" />
        </div>
      </router-link>
      <router-link :to="{name: 'wap.salary'}" tag="div" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.salary')" class="item">
        <div class="wrap">
          <div class="title">
            <div class="name">工资明细</div>
            <img src="/static/images/mobile/menu/9.png" />
          </div>
          <salary class="content" />
        </div>
      </router-link>
      <router-link :to="{name: 'wap.salary_tongcheng'}" tag="div" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.salary_tongcheng')" class="item">
        <div class="wrap">
          <div class="title">
            <div class="name">工资明细</div>
            <img src="/static/images/mobile/menu/9.png" />
          </div>
          <salary />
        </div>
      </router-link>
      <router-link class="item" :to="{name: 'wap.my'}" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.my')" tag="div">
        <div class="wrap" style="height: 100px;">
          <div class="title">
            <div class="name">我的考核</div>
            <img src="/static/images/mobile/menu/4.png" />
          </div>
        </div>
      </router-link>
      <router-link class="item" :to="{name: 'wap.doc.handle'}" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.doc.handle')" tag="div">
        <div class="wrap" style="height: 100px;">
          <div class="title">
            <div class="name">我的公文</div>
            <img src="/static/images/mobile/menu/11.png" />
          </div>
          <div class="content">
            <div style="color: #FFF;" v-if="docNum > 0">{{docNum}}条待办</div>
            <div style="color: #FFF;" v-else>暂无待办</div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="menus" v-if="false">
      <router-link :to="{name: 'wap.salary'}" tag="div" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.salary')" class="item">
        <img src="/static/images/mobile/menu/3.png" />
        <span>工资明细</span>
      </router-link>
      <router-link :to="{name: 'wap.salary_tongcheng'}" tag="div" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.salary_tongcheng')" class="item">
        <img src="/static/images/mobile/menu/3.png" />
        <span>工资明细</span>
      </router-link>
      <router-link class="item" :to="{name: 'wap.my'}" v-if="$store.getters.availableMenus.map(v => v.url).includes('wap.my')" tag="div">
        <img src="/static/images/mobile/menu/4.png" />
        <span>我的考核</span>
      </router-link>
      <div class="item">
        <img src="/static/images/mobile/menu/5.png" />
        <span>药品</span>
      </div>
      <div class="item">
        <img src="/static/images/mobile/menu/2.png" />
        <span>医药</span>
      </div>
      <router-link class="item" :to="{name: 'wap.all_info'}" v-if="workerId">
        <img src="/static/images/mobile/menu/6.png" />
        <span>个人信息</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import Person from './menu/person'
import Salary from './menu/salary'
import { officialDocumentHandleRequest } from '@/api'

export default {
  components: { Person, Salary },
  data () {
    return {
      docNum: 0
    }
  },
  computed: {
    workerId () {
      return (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || {objId: null}).objId
    }
  },
  methods: {
    async loadDocNum () {
      let datas = await officialDocumentHandleRequest.getMy()
      let dataMap = {}
      datas.forEach(v => {
        let key = v.officialDocumentId + '_' + v.workerId
        if (!dataMap[key]) {
          dataMap[key] = v
        } else {
          dataMap[key].status = v.status === 'over' ? 'over' : 'wait'
        }
      })
      this.docNum = Object.keys(dataMap).map(v => dataMap[v]).filter(v => v.status !== 'over').length
    },
  },
  mounted () {
    this.loadDocNum()
  }
}
</script>
